.about-new {

    background-image: url("../../public/assets/new-photo.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 640px) {
        background-image: url("../../public/assets/new-photo-mobile.jpg");
    }

    @media (min-width: 641px) and (max-width: 1024px) {
        background-image: url("../../public/assets/new-photo-tablet.jpg");
    }

    .slide {
        transform: translateX(-100%);
        opacity: 0;
        transition: transform 0.6s ease-in-out,
            opacity 0.6s ease-in-out;
    }

    .active {
        transform: translateX(0%);
        opacity: 1;
    }

    div {

        min-height: 90vh;
        background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) left top/100% 100% no-repeat;

        h1 {

            font-family: $font-title;
            color: aliceblue;
            font-size: 4vw;
            font-weight: 600;
            
            @media (max-width: 1280px) {
                font-size: 6vw;
            }

            @media (max-width: 640px) {
                font-size: 8vw;
            }
        }

        h2 {

            font-family: $font-title;
            color: $main-color;
            font-size: 4vw;
            font-weight: 900;

            @media (max-width: 1280px) {
                font-size: 6vw;
            }

            @media (max-width: 640px) {
                font-size: 8vw;
            }
        }

        p {
            color: aliceblue;
            font-size: 1.8vw;
            font-weight: 200;

            @media (max-width: 1280px) {
                font-size: 2.5vw;
            }

            @media (max-width: 640px) {
                font-size: 4vw;
            }
        }

        a {
            background-color: $main-color;
            font-weight: 900;
            font-family: $font-title;

            &:hover {
                background-color: $main-color-darker;
                color: $text-color;
            }
        }
    }
}