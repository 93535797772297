@keyframes spin_51 {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}
.real {

    min-height: 71vh;

    & h1 {
        font-family: $font-title;
        text-transform: uppercase;
        color: $main-color;
        font-weight: 700;
    }

.loader {
    --dim: 10rem;
    width: var(--dim);
    height: var(--dim);
    border: 7px solid $main-color;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: spin_51 1.5s infinite linear;

}
    .gridvideo {

        .fade-enter {
                opacity: 0;
                transform: scale(0.8);
            }
        
            .fade-enter-active {
                opacity: 1;
                transform: scale(1);
                transition: all 500ms ease-in-out;
            }
        
            .fade-exit {
                opacity: 1;
                transform: scale(1);
            }
        
            .fade-exit-active {
                opacity: 0;
                transform: scale(0.5);
                transition: all 500ms ease-in-out;
            }
        div {

            @keyframes modalAppear {
                from {
                    transform: scale(0);
                }

                to {
                    transform: scale(100%);
                }
            }

            @keyframes modalDisappear {
                from {
                    transform: scale(100%);
                }

                to {
                    transform: scale(0);
                }
            }

            .modaleAppear {
                animation: modalAppear 0.3s ease-in-out;
            }

            .modaleDisappear {
                animation: modalDisappear 0.3s ease-in-out;
            }

            .modaleVideo {
                position: relative;
                background-color: $background-modale;
                border-radius: 2rem;
                padding: 2em;
                width: 80%;
                height: 90vh;
                position: fixed;
                top: 3rem;
                left: 12vw;
                z-index: 2;
                object-fit: fill;

                @media (min-width: 640px) and (max-width: 1024px) {
                        top: 3rem;
                        left: 10vw;
                            height: 50vh;
                    }
                
                    @media (max-width: 640px) {
                        top: 9rem;
                        left: 2.5rem;
                        height: 50vh;
                    }
@media only screen and (max-height: 575.98px) and (orientation: landscape) {
    top: 1rem;
    height: 95vh;
}

.react_player {
    aspect-ratio: 16/9;
    object-fit: cover;
}
                & h3 {
                    font-family: $font-title;
                    color: $text-color;
                    text-transform: uppercase;
                }

                & .cross-modale {
                    position: absolute;
                    top: 1.2em;
                    left: 1.2em;
                    font-size: 2em;
                    color: $main-color;
                @media (max-width: 640px) {
                        font-size: 1.3em;
                        top: 0.9em;
                        left: 0.9em;
                    }
                }
            }
        }
    }
}