.header {

    width: 100%;
    height: 40vw;
    
    @media (min-width: 640px) and (max-width: 1024px) {
            height: 70vw;
        }
    
        @media (max-width: 640px) {
            height: 120vw;
        }


    div {

        h1 {

            font-family: $font-title;
            color: $main-color;
            font-size: 4vw;
            font-weight: 900;

            @media (max-width: 1280px) {
                font-size: 6vw;
            }

            @media (max-width: 640px) {
                font-size: 8vw;
            }
        }
        p {
            font-family: $font-title;
            color: $main-color;
            font-size: 1.8vw;
            font-weight: lighter;

            @media (max-width: 1280px) {
                font-size: 2vw;
            }

            @media (max-width: 640px) {
                font-size: 3vw;
            }
        }
    }
}