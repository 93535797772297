.banner {

    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 45vw;
    
        @media (min-width: 640px) and (max-width: 1024px) {
            height: 70vw;
            background-attachment: scroll;
        }
        @media (max-width: 640px) {
            height: 120vw;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (-webkit-min-device-pixel-ratio: 2) {
            background-attachment: scroll;
        }

    & div {
        width: 100%;
            height: 100%;
            margin: 0;
            z-index: 20;
            position: inherit;
            .slide {
                transform: translateX(-100%);
                opacity: 0;
                transition: transform 0.6s ease-in-out,
                    opacity 0.6s ease-in-out;
            }
        
            .active {
                transform: translateX(0%);
                opacity: 1;
            }
        & h2 {
            font-size: 6em;
            color: $text-color;
            font-family: $font-title;
            text-transform: uppercase;
            @media (min-width: 640px) and (max-width: 1024px) {
                    font-size: 4em;
                }
            
                @media (max-width: 640px) {
                    font-size: 3em;
                }
        }

        & p {
            font-size: 1.3em;
            color: $text-color;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 1em;
            @media (min-width: 640px) and (max-width: 1024px) {
                    font-size: 1em;
                }
            
                @media (max-width: 640px) {
                    font-size: 1em;
                }
        }

        & a {
            align-self: flex-start;
            font-size: 1.3em;
            color: $main-color;
            text-transform: uppercase;
            letter-spacing: 3px;
            border-bottom: 2px solid $main-color;

            @media (min-width: 640px) and (max-width: 1024px) {
                    font-size: 1.1em;
                }
            
                @media (max-width: 640px) {
                    font-size: 1.2em;
                }

            &::after {
                margin-left: 0.6em;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f30b";
            }
            &:hover,
            &hover::after {
                color: $main-color-darker;
                border-bottom: 2px solid $main-color-darker;
                transition: all 0.3s;
            }
        }
    }
}