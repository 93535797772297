footer {

    background-color: $background-color;
    padding: 2em 2em 0 2em;

    div {

        .social-buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: -10px;
        }

        .social-buttons__button {
            margin: 10px 5px 0;
        }

        .social-button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            width: 50px;
            height: 50px;
            text-decoration: none;
            border-radius: 100%;
            background: $main-color;
            text-align: center;
        }

        .social-button::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 50%;
            display: block;
            width: 0;
            height: 0;
            border-radius: 100%;
            transition: 0.3s;
        }

        .social-button:focus,
        .social-button:hover {
            color: #fff;
        }

        .social-button:focus::after,
        .social-button:hover::after {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            margin-left: calc(-50% - 1px);
        }

        .social-button i,
        .social-button svg {
            position: relative;
            z-index: 1;
            transition: 0.3s;
        }

        .social-button i {
            font-size: 23px;
        }

        .social-button--mail {
            color: $background-color;
        }

        .social-button--mail::after {
            background: #f4952f;
        }

        .social-button--instagram {
            color: $background-color;
        }

        .social-button--instagram::after {
            background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
        }

        .social-button--linkedin {
            color: $background-color;
        }

        .social-button--linkedin::after {
            background: #0077b5;
        }

        .social-button--youtube {
            color: $background-color;
        }

        .social-button--youtube::after {
            background: #f42a2a;
        }

        .social-button--tiktok {
            color: $background-color;
        }

        .social-button--tiktok::after {
            background: #000d14;
        }

        .social-button--twitch {
            color: $background-color;
        }

        .social-button--twitch::after {
            background: #6441a5;
        }
    }

    .mentions {
        color: $text-color;
        padding-top: 2em;
    }

    .hastag {
        color: $text-color;
        padding-top: 0.5em;
    }
}