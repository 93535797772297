.contact {

    h1 {
        font-family: $font-title;
        color: $main-color;
        font-weight: 900;
        text-transform: uppercase;
    }

    .img-contact img {
        box-shadow: -30px 30px 0 $main-color;
        border-radius: 10px;
    }

    .formulaire {

        input {
                margin: 1.2vw;
        
                @media (min-width: 640px) and (max-width: 1024px) {
                    margin: 1.8vw;
                }
        
                @media (max-width: 640px) {
                    margin: 2vw;
                }
            }
        input:focus {
            outline: none;
        }

textarea {
    margin: 1.2vw;

    @media (min-width: 640px) and (max-width: 1024px) {
        margin: 1.8vw;
    }

    @media (max-width: 640px) {
        margin: 2vw;
    }
}
        textarea:focus {
            outline: none;
        }

        button {
            background-color: $main-color;
            font-weight: 900;
            font-family: $font-title;

            &:hover {
                background-color: $main-color-darker;
                color: $text-color;
            }
        }
    }
}