@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap");
.nav .logo {
  transition: all 0.3s ease-in-out;
}
.nav .logo img {
  width: 10vw;
  height: auto;
}
@media (max-width: 640px) {
  .nav .logo img {
    width: 30vw;
  }
}
.nav .logo:hover {
  transform: scale(1.1);
}
.nav .nav_link a {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #DFC423;
  transition: all 0.5s;
  border-bottom: solid 2px transparent;
  position: relative;
}
.nav .nav_link a::after {
  position: absolute;
  top: 2em;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #DFC423;
  height: 2px;
  transition: all 0.5s;
}
.nav .nav_link a:hover::after {
  width: 100%;
}
.nav .nav_link a:hover {
  color: #DFC423;
}

.header {
  width: 100%;
  height: 40vw;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .header {
    height: 70vw;
  }
}
@media (max-width: 640px) {
  .header {
    height: 120vw;
  }
}
.header div h1 {
  font-family: "Montserrat", sans-serif;
  color: #DFC423;
  font-size: 4vw;
  font-weight: 900;
}
@media (max-width: 1280px) {
  .header div h1 {
    font-size: 6vw;
  }
}
@media (max-width: 640px) {
  .header div h1 {
    font-size: 8vw;
  }
}
.header div p {
  font-family: "Montserrat", sans-serif;
  color: #DFC423;
  font-size: 1.8vw;
  font-weight: lighter;
}
@media (max-width: 1280px) {
  .header div p {
    font-size: 2vw;
  }
}
@media (max-width: 640px) {
  .header div p {
    font-size: 3vw;
  }
}

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 45vw;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .banner {
    height: 70vw;
    background-attachment: scroll;
  }
}
@media (max-width: 640px) {
  .banner {
    height: 120vw;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1) and (-webkit-min-device-pixel-ratio: 2) {
  .banner {
    background-attachment: scroll;
  }
}
.banner div {
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 20;
  position: inherit;
}
.banner div .slide {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}
.banner div .active {
  transform: translateX(0%);
  opacity: 1;
}
.banner div h2 {
  font-size: 6em;
  color: aliceblue;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .banner div h2 {
    font-size: 4em;
  }
}
@media (max-width: 640px) {
  .banner div h2 {
    font-size: 3em;
  }
}
.banner div p {
  font-size: 1.3em;
  color: aliceblue;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 1em;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .banner div p {
    font-size: 1em;
  }
}
@media (max-width: 640px) {
  .banner div p {
    font-size: 1em;
  }
}
.banner div a {
  align-self: flex-start;
  font-size: 1.3em;
  color: #DFC423;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-bottom: 2px solid #DFC423;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .banner div a {
    font-size: 1.1em;
  }
}
@media (max-width: 640px) {
  .banner div a {
    font-size: 1.2em;
  }
}
.banner div a::after {
  margin-left: 0.6em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f30b";
}
.banner div a:hover, .banner div ahover::after {
  color: #bda520;
  border-bottom: 2px solid #bda520;
  transition: all 0.3s;
}

footer {
  background-color: #141414;
  padding: 2em 2em 0 2em;
}
footer div .social-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;
}
footer div .social-buttons__button {
  margin: 10px 5px 0;
}
footer div .social-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 50px;
  height: 50px;
  text-decoration: none;
  border-radius: 100%;
  background: #DFC423;
  text-align: center;
}
footer div .social-button::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: 0.3s;
}
footer div .social-button:focus,
footer div .social-button:hover {
  color: #fff;
}
footer div .social-button:focus::after,
footer div .social-button:hover::after {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin-left: calc(-50% - 1px);
}
footer div .social-button i,
footer div .social-button svg {
  position: relative;
  z-index: 1;
  transition: 0.3s;
}
footer div .social-button i {
  font-size: 23px;
}
footer div .social-button--mail {
  color: #141414;
}
footer div .social-button--mail::after {
  background: #f4952f;
}
footer div .social-button--instagram {
  color: #141414;
}
footer div .social-button--instagram::after {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}
footer div .social-button--linkedin {
  color: #141414;
}
footer div .social-button--linkedin::after {
  background: #0077b5;
}
footer div .social-button--youtube {
  color: #141414;
}
footer div .social-button--youtube::after {
  background: #f42a2a;
}
footer div .social-button--tiktok {
  color: #141414;
}
footer div .social-button--tiktok::after {
  background: #000d14;
}
footer div .social-button--twitch {
  color: #141414;
}
footer div .social-button--twitch::after {
  background: #6441a5;
}
footer .mentions {
  color: aliceblue;
  padding-top: 2em;
}
footer .hastag {
  color: aliceblue;
  padding-top: 0.5em;
}

@keyframes spin_51 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.real {
  min-height: 71vh;
}
.real h1 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #DFC423;
  font-weight: 700;
}
.real .loader {
  --dim: 10rem;
  width: var(--dim);
  height: var(--dim);
  border: 7px solid #DFC423;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin_51 1.5s infinite linear;
}
.real .gridvideo .fade-enter {
  opacity: 0;
  transform: scale(0.8);
}
.real .gridvideo .fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 500ms ease-in-out;
}
.real .gridvideo .fade-exit {
  opacity: 1;
  transform: scale(1);
}
.real .gridvideo .fade-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: all 500ms ease-in-out;
}
@keyframes modalAppear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(100%);
  }
}
@keyframes modalDisappear {
  from {
    transform: scale(100%);
  }
  to {
    transform: scale(0);
  }
}
.real .gridvideo div .modaleAppear {
  animation: modalAppear 0.3s ease-in-out;
}
.real .gridvideo div .modaleDisappear {
  animation: modalDisappear 0.3s ease-in-out;
}
.real .gridvideo div .modaleVideo {
  position: relative;
  background-color: #363636;
  border-radius: 2rem;
  padding: 2em;
  width: 80%;
  height: 90vh;
  position: fixed;
  top: 3rem;
  left: 12vw;
  z-index: 2;
  object-fit: fill;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .real .gridvideo div .modaleVideo {
    top: 3rem;
    left: 10vw;
    height: 50vh;
  }
}
@media (max-width: 640px) {
  .real .gridvideo div .modaleVideo {
    top: 9rem;
    left: 2.5rem;
    height: 50vh;
  }
}
@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .real .gridvideo div .modaleVideo {
    top: 1rem;
    height: 95vh;
  }
}
.real .gridvideo div .modaleVideo .react_player {
  aspect-ratio: 16/9;
  object-fit: cover;
}
.real .gridvideo div .modaleVideo h3 {
  font-family: "Montserrat", sans-serif;
  color: aliceblue;
  text-transform: uppercase;
}
.real .gridvideo div .modaleVideo .cross-modale {
  position: absolute;
  top: 1.2em;
  left: 1.2em;
  font-size: 2em;
  color: #DFC423;
}
@media (max-width: 640px) {
  .real .gridvideo div .modaleVideo .cross-modale {
    font-size: 1.3em;
    top: 0.9em;
    left: 0.9em;
  }
}

.youtube div h1 {
  font-family: "Montserrat", sans-serif;
  color: aliceblue;
  font-size: 2.5vw;
  font-weight: 600;
}
@media (max-width: 640px) {
  .youtube div h1 {
    font-size: 8vw;
  }
}
.youtube div h2 {
  font-family: "Montserrat", sans-serif;
  color: #DFC423;
  font-size: 2.5vw;
  font-weight: 900;
}
@media (max-width: 640px) {
  .youtube div h2 {
    font-size: 8vw;
  }
}

.contact h1 {
  font-family: "Montserrat", sans-serif;
  color: #DFC423;
  font-weight: 900;
  text-transform: uppercase;
}
.contact .img-contact img {
  box-shadow: -30px 30px 0 #DFC423;
  border-radius: 10px;
}
.contact .formulaire input {
  margin: 1.2vw;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .contact .formulaire input {
    margin: 1.8vw;
  }
}
@media (max-width: 640px) {
  .contact .formulaire input {
    margin: 2vw;
  }
}
.contact .formulaire input:focus {
  outline: none;
}
.contact .formulaire textarea {
  margin: 1.2vw;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .contact .formulaire textarea {
    margin: 1.8vw;
  }
}
@media (max-width: 640px) {
  .contact .formulaire textarea {
    margin: 2vw;
  }
}
.contact .formulaire textarea:focus {
  outline: none;
}
.contact .formulaire button {
  background-color: #DFC423;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.contact .formulaire button:hover {
  background-color: #bda520;
  color: aliceblue;
}

.about-new {
  background-image: url("../../public/assets/new-photo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 640px) {
  .about-new {
    background-image: url("../../public/assets/new-photo-mobile.jpg");
  }
}
@media (min-width: 641px) and (max-width: 1024px) {
  .about-new {
    background-image: url("../../public/assets/new-photo-tablet.jpg");
  }
}
.about-new .slide {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}
.about-new .active {
  transform: translateX(0%);
  opacity: 1;
}
.about-new div {
  min-height: 90vh;
  background: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0)) left top/100% 100% no-repeat;
}
.about-new div h1 {
  font-family: "Montserrat", sans-serif;
  color: aliceblue;
  font-size: 4vw;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .about-new div h1 {
    font-size: 6vw;
  }
}
@media (max-width: 640px) {
  .about-new div h1 {
    font-size: 8vw;
  }
}
.about-new div h2 {
  font-family: "Montserrat", sans-serif;
  color: #DFC423;
  font-size: 4vw;
  font-weight: 900;
}
@media (max-width: 1280px) {
  .about-new div h2 {
    font-size: 6vw;
  }
}
@media (max-width: 640px) {
  .about-new div h2 {
    font-size: 8vw;
  }
}
.about-new div p {
  color: aliceblue;
  font-size: 1.8vw;
  font-weight: 200;
}
@media (max-width: 1280px) {
  .about-new div p {
    font-size: 2.5vw;
  }
}
@media (max-width: 640px) {
  .about-new div p {
    font-size: 4vw;
  }
}
.about-new div a {
  background-color: #DFC423;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.about-new div a:hover {
  background-color: #bda520;
  color: aliceblue;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.background {
  background-color: #141414;
  background-repeat: no-repeat;
  background-size: cover;
}

