@import 'variables.scss';
@import 'nav.scss';
@import 'header.scss';
@import 'banner.scss';
@import 'footer.scss';
@import 'realisation.scss';
@import 'youtube.scss';
@import 'contact.scss';
@import 'about.scss';


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.background {
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
}