.youtube {

    div {

        h1 {

            font-family: $font-title;
            color: aliceblue;
            font-size: 2.5vw;
            font-weight: 600;
            
            @media (max-width: 640px) {
                font-size: 8vw;
            }
        }

        h2 {

            font-family: $font-title;
            color: $main-color;
            font-size: 2.5vw;
            font-weight: 900;

            @media (max-width: 640px) {
                font-size: 8vw;
            }
        }
    }
}